<template>
  <v-form>
    <v-container fluid>

      <!-- <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.fileImage"
            :error-messages="fileImageErrors"
            :label="$t('fileImage')"
            @input="$v.item.fileImage.$touch()"
            @blur="$v.item.fileImage.$touch()"
          />
        </v-col>
      </v-row> -->

      <v-row >
        <v-col offset-md="3" cols="1" sm="1" md="1">
          <v-checkbox
            v-model="item.publie"
            :error-messages="publieErrors"
            :label="$t('Publié')"
            @input="$v.item.publie.$touch()"
            @blur="$v.item.publie.$touch()"
          />
        </v-col>
        <v-col cols="5" sm="5" md="5">
          <v-text-field
            v-model="item.titre"
            :error-messages="titreErrors"
            :label="$t('Titre')"
            @input="$v.item.titre.$touch()"
            @blur="$v.item.titre.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <media-object v-model="item.mediaObjectImage" is-image max-height="25vh"/>
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.description"
            :error-messages="descriptionErrors"
            :label="$t('Résumé court')"
            hint="150 caractères maximum"
            persistent-hint
            @input="$v.item.description.$touch()"
            @blur="$v.item.description.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <wysiwyg
            v-model="item.contenu"
            :error-messages="contenuErrors"
            :label="$t('Contenu')"
            @input="$v.item.contenu.$touch()"
            @blur="$v.item.contenu.$touch()"
          />
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <InputDate
            v-model="item.createdAt"
            :label="$t('createdAt')"
            :error-messages="createdAtErrors"
          />
        </v-col>
      </v-row> -->

      <!-- <v-row>
        <v-col offset-md="3" cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.slug"
            :error-messages="slugErrors"
            :label="$t('slug')"
            @input="$v.item.slug.$touch()"
            @blur="$v.item.slug.$touch()"
          />
        </v-col>
      </v-row> -->

    </v-container>
  </v-form>
</template>

<script>
import has from "lodash/has";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import InputDate from "../InputDate";
import { date } from "../../validators/date";
import MediaObject from "../MediaObject";

export default {
  name: "ArticleForm",
  mixins: [validationMixin],
  components: {
    MediaObject,
    InputDate,
  },
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {},
  data() {
    return {
      fileImage: null,
      titre: null,
      image: null,
      contenu: null,
      publie: null,
      description: null,
      createdAt: null,
      slug: null,
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    fileImageErrors() {
      const errors = [];

      if (!this.$v.item.fileImage.$dirty) return errors;

      has(this.violations, "fileImage") &&
        errors.push(this.violations.fileImage);

      return errors;
    },
    titreErrors() {
      const errors = [];

      if (!this.$v.item.titre.$dirty) return errors;

      has(this.violations, "titre") && errors.push(this.violations.titre);

      return errors;
    },
    imageErrors() {
      const errors = [];

      if (!this.$v.item.image.$dirty) return errors;

      has(this.violations, "image") && errors.push(this.violations.image);

      return errors;
    },
    contenuErrors() {
      const errors = [];

      if (!this.$v.item.contenu.$dirty) return errors;

      has(this.violations, "contenu") && errors.push(this.violations.contenu);

      return errors;
    },
    publieErrors() {
      const errors = [];

      if (!this.$v.item.publie.$dirty) return errors;

      has(this.violations, "publie") && errors.push(this.violations.publie);

      return errors;
    },
    descriptionErrors() {
      const errors = [];

      if (!this.$v.item.description.$dirty) return errors;

      has(this.violations, "description") &&
        errors.push(this.violations.description);

      return errors;
    },
    createdAtErrors() {
      const errors = [];

      if (!this.$v.item.createdAt.$dirty) return errors;

      has(this.violations, "createdAt") &&
        errors.push(this.violations.createdAt);

      return errors;
    },
    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, "slug") && errors.push(this.violations.slug);

      return errors;
    },

    violations() {
      return this.errors || {};
    },
  },
  methods: {},
  validations: {
    item: {
      fileImage: {},
      titre: {},
      image: {},
      contenu: {},
      publie: {},
      description: {},
      createdAt: {
        date
      },
      slug: {}
    }
  }
};
</script>
